#profile {
  width: auto;
  max-width: 300px;
  height: 100%;
  object-fit: cover;
  border-left: 1px solid #201e1b;
}

@media screen and (min-width: 1024px) {
  #profile {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #profile {
    display: none;
  }
}
