* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  color: #4a4a4a;
  background-color: #201e1b;
  overflow: hidden;
}

.bold {
  font-weight: bold;
}
