#info {
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;

  div {
    flex: 1;
    h1 {
      font-weight: 300;
      line-height: 1.2em;
    }

    h3 {
      font-weight: 300;
      margin-top: 16px;

      svg {
        width: 1em;
        height: 1em;
        margin: 2px 4px 4px 4px;
        vertical-align: middle;
        fill: #4a4a4a;
      }
    }

    .icon {
      width: 1em;
      height: 1em;
      margin: 2px 4px 4px 4px;
      vertical-align: middle;
    }

    #separator {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px dashed #4a4a4a;
      margin: 2em 0;
      padding: 0;
    }
  }

  #copyright {
    margin-bottom: 8px;
    font-size: 0.7em;
    font-weight: 300;
    width: 100%;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  #info {
    div {
      text-align: center;
      h3 svg {
        fill: #fff;
        margin: 0px 6px 6px 4px;
      }
      #separator {
        display: none;
      }
    }
    #copyright {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 374px) {
  #info {
    h1 {
      font-size: 1.6em;
    }
    h3 {
      font-size: 1em;
    }
  }
}
