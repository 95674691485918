#social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  a {
    padding-bottom: 4px;
    border-bottom: 2px solid #4a4a4a;

    &:hover {
      border-bottom: 2px solid #979797;
      svg {
        fill: #979797;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #4a4a4a;
    }
  }
  /* gap between images */
  & > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 767px) {
  #social {
    flex-flow: row wrap;
    margin-top: 16px;
    a {
      width: 100%;
      padding: 8px;
      margin: 4px 0;
      border: 1px solid #4a4a4a;

      svg {
        fill: #fff;
      }

      &:hover {
        border: 1px solid #fff;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  #social {
    a {
      width: calc(50% - 16px);
      margin: 8px;
    }
  }
}
