$boder-size: 16px;

#top,
#left,
#right,
#bottom {
  position: fixed;
  background-color: #201e1b;
  z-index: 10;
}

#top,
#bottom {
  width: 100%;
  height: $boder-size;
  left: 0;
  right: 0;
}

#right,
#left {
  width: $boder-size;
  height: 100%;
  top: 0;
  bottom: 0;
}

#top {
  top: 0;
}

#bottom {
  bottom: 0;
}

#left {
  left: 0;
}

#right {
  right: 0;
}

@media screen and (max-width: 767px) {
  #top,
  #left,
  #right,
  #bottom {
    display: none;
  }
}
