#content {
  background-color: #fff;
  margin: 8px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;

  #info {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  #content {
    margin: 0;
    background-color: #201e1b;
    color: #fff;
  }
}
